/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import { SectionTitle } from "./SectionTitle"

interface Props {
  children?: React.ReactNode
  sectionTitle?: string
}

const Layout = ({ children, sectionTitle }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="body-wrap boxed-container">
        <Header
          siteTitle={data.site.siteMetadata.title}
          isForLandingPage={!sectionTitle}
        />
        {sectionTitle && <SectionTitle sectionTitle={sectionTitle} />}
        <div id="main-container ">
          <main>{children}</main>
          <footer className="mt-auto">
            <p>
              {`Copyright © ${new Date().getFullYear()} Orthodontie Heidbuchel BV.\n`}
              <br />
              Ontworpen door <Link to="http://deconpany.be">DeConpany.be</Link>
            </p>
          </footer>
        </div>
      </div>
    </>
  )
}

export default Layout
