import React from "react"
import HeaderBack from "../assets/svgs/ultimate-section.svg"
export const SectionTitle = (props: { sectionTitle: string }) => {
  return (
    <div
      className="section-title"
      // style={{
      //   width: "100%",
      //   height: "200px",
      //   background: "green",
      //   marginTop: "-60px",
      // }}
    >
      <HeaderBack />
      <h1>{props.sectionTitle} </h1>
    </div>
  )
}
